<template>
  <section class="section bg-darkslategray" id="features">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <img
            src="../../assets/images/manage/alerty.png"
            class="img-fluid fadeIn animated wow center-block"
            data-wow-delay=".2s"
          />
        </div>
        <div class="col-lg-6">
          <div class="feature-detail">
            <div class="title-box">
              <h2
                class="fadeIn animated wow text-white"
                data-wow-delay=".1s"
              >{{$t("KURLABS MONITORING - ALERTY")}}</h2>
              <div class="border"></div>
            </div>

            <li class="text-white my-3">{{$t("Regular and constant monitoring of web services.")}}</li>

            <li class="text-white my-3">{{$t("SMS, email and Slack notifications.")}}</li>

            <li class="text-white my-3">{{$t("Generation of service activity reports.")}}</li>
            <li class="text-white my-3">{{$t("Monitor JS elements, css and images.")}}</li>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ManageControl"
};
</script>







